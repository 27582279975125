import React, { useState } from 'react';
import { Button } from '../shared/Button';
import { TextInput } from '../shared/inputs/TextInput';
import { ResponseModel } from '../../models/ResponseModel';
import { resetPassword } from '../../services/AccountService';
import { PasswordModel } from '../../models/PasswordModel';
import { LoginPageLoader } from '../loaders/LoginPageLoader';

interface Props {
    styles: any;
    email: string;
    code: string;     
    setError(isError: boolean): void;
    setStage(stage: string): void;
    openMessageDialog(message: string): void;
}

export const ResetPassword: React.FC<Props> = (props) => {

    const intialFormInputs: PasswordModel = {
        email: props.email,
        code: props.code,        
        newPassword: "",  
        confirmNewPassword: ""
    }
    const [passwordModel, setPasswordModel] = useState<PasswordModel>(intialFormInputs);
    const [loading, setLoading] = useState<boolean>(false);     
    const styles = props.styles;

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        setPasswordModel((values: any) => ({ ...values, [name]: value }));
    }

    async function submitEmail(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setLoading(true);
        await resetPassword(passwordModel)
        .then((response: ResponseModel) => {            
            setLoading(false);
            props.setError(!response.Status);
            props.openMessageDialog(response.Message);            
        }) 
        .catch((error) => {
            setLoading(false);           
            //toErrorPage(error);
        });
    }
    
    return (
        <form style={styles.form} onSubmit={submitEmail}>
            <h1 style={styles.title}>Change Password</h1>
            <p style={styles.subTitle}>Please choose a new password.</p>
            {loading ? <LoginPageLoader/> : <>
                <TextInput label={'New Password *'} type={'password'} name={"newPassword"} onChange={handleInputChange} value={passwordModel.newPassword}/>
                <TextInput label={'Confirm New Password *'} type={'password'} name={"confirmNewPassword"} onChange={handleInputChange} value={passwordModel.confirmNewPassword}/>
                <Button type="submit" style={styles.submitButton}>{"RESET PASSWORD"}</Button>
            </>}
            <div style={styles.rowWrapper}>
                <Button style={{...styles.redirectButton, ...styles.redirectButton.left}} onClick={() => {props.setStage("register")}}>Register</Button>
                <Button style={{...styles.redirectButton, ...styles.redirectButton.right}} onClick={() => {props.setStage("login")}}>Login</Button>
            </div>
        </form>
    )
}