import React from 'react';

interface Props {
    open: boolean;
    styles: any;
    children?: React.ReactNode;
    toggle?(): void;    
}

export const PopUpDialog: React.FC<Props> = (props) => {   
    const styles = props.styles;
    if (!props.open) return null;

    return (
        <>
            <div style={styles.backdrop} onClick={props.toggle}></div>
            <div style={styles.content}>                
                {props.children}
            </div>
        </>
    )
}