import { Color } from "../enums/Color";

export const dialog = {
    mobile: {
        dialog: {
            maxHeight: "70%",
            display: "flex",
            flexDirection: "column",
            background: Color.White,
            position: "fixed",
            top: "50%",
            left: "50%",
            width: "80%",
            padding: "10px 5%",                        
            borderRadius: "10px",
            zIndex: "2",
            boxShadow: "1px 0px 7px rgba(0,0,0,0.5)",  
            transform: "translate(-50%, -50%)"
        },
        backdrop: {
            backgroundColor: Color.Black,
            opacity: 0.15,
            position: "fixed" as "fixed",
            left: "0",
            right: "0",
            bottom: "0",
            top: "0"
        },
        menuDialog: {
            background: Color.Ash
        },                 
        title: {
            height: "7%",
            textAlign: "center",
            fontWeight: "bold",
            color: Color.Green        
        },
        container: {
            marginTop: "16px",
            overflow: "scroll"            
        },
        search: {
            lineHeight: "34px",
            width: "-webkit-fill-available",
            backgroundColor: Color.White,
            border: "2px solid " + Color.Green,
            borderRadius: "20px",
            marginTop: "12px",
            padding: "0 15px"
        },
        labelWrapper: {
            display: "flex",
            flexDirection: "row",
            borderBottom: "1px solid " + Color.DeepAsh,
            marginBottom: "20px",
            paddingBottom: "5px",
            cursor: "pointer"            
        },
        labelIcon: {
            marginLeft: "auto",
            marginTop: "8px",            
            width: "10px"
        },
        searchLabelWrapper: {
            fontWeight: 700,
            backgroundColor: Color.Ash,
            lineHeight: "28px",
            borderRadius: "5px",
            paddingLeft: "12px",
            marginBottom: "8px"
        },
        optionContainer: {
            paddingLeft: "12px"
        },
        optionWrapper: {
            display: "flex",
            flexDirection: "row",
            borderBottom: "2px solid " + Color.Ash,
            paddingBottom: "10px",
            marginBottom: "10px",
            cursor: "pointer"
        },
        option: {
            marginLeft: "5px"
        },
        secondaryOption: {
            fontSize: "12px"
        },        
        buttonWrapper: {
            paddingTop: "10px"
        },
        button: {            
            background: "transparent",
            color: Color.Green,
            fontSize: "16px",
            fontWeight: "bold",
            border: "none",            
            select: {
                float: "right"
            }
        },
        expand: {
            display: "block",
            overflow: "scroll",
            background: Color.Ash,
            marginTop: "-20px",
            marginBottom: "20px",
            borderRadius: "0 0 10px 10px"
        },
        minimize: {
            display: "none"
        }
    },
    desktop: {
        dialog:{
            width: "34%",
            padding: "2% 3%",
            borderRadius: "10px",
            boxShadow: "1px 0px 7px rgba(0,0,0,0.5)"
        },                
        title: {
            fontSize: "1.5vw",
            marginBottom: "2vh"
        },
        container: {
            height: "84%",
            overflow: "auto"            
        },
        optionWrapper: {
            paddingBottom: "1vh",
            marginBottom: "2vh"            
        },
        option: {
            fontSize: "18px"
        },
        secondaryOption: {
            fontSize: "14px"
        },
        button: {
            fontSize: "20px"
        },
        expand: {        
            overflow: "auto"        
        }
    }
}