import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { StyleContext } from "../providers/StyleContextProvider";
import { ReactComponent as WhatsApp } from "../assets/svgs/whatsapp-logo.svg";
import { ReactComponent as Teachers } from "../assets/svgs/teachers.svg";
import { ReactComponent as Degrees } from "../assets/svgs/degrees.svg";
import { useNavigate } from "react-router-dom";
import { Admin } from "../models/Admin";
import { AuthContext } from "../providers/AuthContextProvider";
import { Footer } from "../components/shared/Footer";

export const HomePage: React.FC = () => {
    const navigate = useNavigate();
    const styleContext = useContext(StyleContext);
    const authContext = useContext(AuthContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("homePage"));  
    const admin: Admin | null = authContext.getLoggedAdmin();     
    const [loading, setLoading] = useState<boolean>(true);    
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });  

    useEffect(() => {                        
        if(admin === null) {            
            navigate('/account/login');
        }  
    });

    useEffect(() => {  
        window.scrollTo(0, 0);                                     
    }, []);

    useEffect(() => {        
        setStyles(styleContext.getComponentStyle("homePage")); 
    }, [isMobile]);

    return (   
        <>
            <div style={styles}>
                <div style={styles.title}>{"TuteClass"}</div>
                <div style={styles.wrapper}>
                    <div style={styles.rowWrapper}>
                        <div style={styles.card} onClick={() => navigate('/whatsapp-manager')}>                            
                            <WhatsApp style={styles.card.icon} />
                            <span style={styles.card.name}>{"WhatsApp Manager"}</span>                       
                        </div> 
                        <div style={styles.card} onClick={() => navigate('/teachers')}>                            
                            <Teachers style={styles.card.icon} />
                            <span style={styles.card.name}>{"Teachers"}</span>                       
                        </div>  
                        <div style={styles.card} onClick={() => navigate('/degrees')}>                            
                            <Degrees style={styles.card.icon} />
                            <span style={styles.card.name}>{"Degrees"}</span>                       
                        </div>                                           
                    </div>
                </div>           
            </div>     
            <Footer />
        </>         
    )
}