import axios from "axios";
import { CommonTexts as Texts} from "../helpers/LayoutTexts";
import { WhatsAppContact } from "../models/WhatsAppContact";
import { WhatsAppSendMessage } from "../models/WhatsAppSendMessage";

export async function getWhatsAppContacts() {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "whatsapp/contacts")
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getWhatsAppMessages(phoneNumber: string) {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "whatsapp/messagesByNumber", {
            params: {
                phoneNumber: phoneNumber                
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function sendWhatsAppTemplateTextMessage(sendMessage: WhatsAppSendMessage) {
    return new Promise<any>((resolve, reject) => {
        axios
            .post(Texts.apiBaseUrl + "whatsapp/templateText", sendMessage)
            .then((response) => {                
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    });
}

export async function sendWhatsAppTextMessage(sendMessage: WhatsAppSendMessage) {
    return new Promise<any>((resolve, reject) => {
        axios
            .post(Texts.apiBaseUrl + "whatsapp/textMessage", sendMessage)
            .then((response) => {                
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    });
}

export async function sendWhatsAppMediaMessage(formData: FormData) {
    return new Promise<any>((resolve, reject) => {
        axios
        .post(Texts.apiBaseUrl + "whatsapp/mediaMessage", formData)
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function sendWhatsAppPromotionPost(formData: FormData) {
    return new Promise<any>((resolve, reject) => {
        axios
        .post(Texts.apiBaseUrl + "whatsapp/promotionPost", formData)
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}