import { Color } from "../enums/Color";
import { Font } from "../enums/Font";

export const homePage = {    
    mobile: {
        title: {            
            paddingLeft: "4%", 
            textAlign: "left" as "left",                
            fontSize: "36px",        
            fontFamily: Font.Advanced,        
            letterSpacing: "0px",
            color: Color.AquaGreen,            
            cursor: "pointer",
            opacity: "1"
        }
    },
    desktop: {
        height: "720px",
        backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/sub_header.jpg)",
        backgroundSize: "100%",
        marginBottom: "-80px",
        title: {                
            paddingTop: "16px",
            paddingLeft: "50px",                            
            fontSize: "46px"
        },
        wrapper: {
            margin: "60px 180px"
        },
        rowWrapper: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
        },
        card: {
            display: "flex",
            flexDirection: "row",
            height: "92px",
            width: "260px",
            borderRadius: "10px",
            background: Color.White,
            cursor: "pointer",
            alignItems: "center",
            justifyContent: "center",
            icon: {                
                width: "46px",
                height: "46px",
                marginRight: "8px",
                fill: Color.Green
            },
            name: {
                fontSize: "18px",
                fontWeight: "bold",
                marginLeft: "8px"
            }
        }
    }
}