import React from "react";
import ContentLoader from "react-content-loader";
import { Color } from "../../enums/Color";

export const LoginPageLoader: React.FC = (props) => {

    return (        
        <>
            <ContentLoader 
                speed={2}
                width={"100%"}
                height={200}                
                backgroundColor={Color.LoaderBackground}
                foregroundColor={Color.LoaderForeground}
                {...props}
            >
                <rect x="0" y="0" rx="3" ry="3" width="100%" height="20" />                 
                <rect x="0" y="50" rx="3" ry="3" width="100%" height="20" />                
                <rect x="0" y="120" rx="3" ry="3" width="100%" height="50" />                
            </ContentLoader>
        </>
    )
}