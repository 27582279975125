import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { StyleContext } from '../../../providers/StyleContextProvider';
import { Button } from '../Button';
import { merge } from 'react-merge';
import { MenuOption } from '../../../models/MenuOption';
import { ReactComponent as Dropdown } from "../../../assets/svgs/drop_down.svg";

interface Props { 
    styles?: any;        
    title: string;      
    menuOptions: MenuOption[];    
    getMenuSelected(menuOptions: MenuOption[]): void;    
    closeDialog(): void;    
};

export const MultySelectMultyMenuDialog: React.FC<Props> = (props) => {    
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("dialog"));
    const [menuOptions, setMenuOptions] = useState<MenuOption[]>(props.menuOptions);    
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });

    useEffect(() => {           
        let style = styleContext.getComponentStyle("dialog");
        let extendedStyle = merge(style, props.styles);
        setStyles(extendedStyle);
    }, [isMobile]);

    const itemChecked = (selectedParentIndex: number, selectedChildIndex: number, check: boolean) => {        
        let tempMenuOptions = [...menuOptions];
        tempMenuOptions[selectedParentIndex].Options[selectedChildIndex].Checked = check;              
        setMenuOptions(tempMenuOptions);        
    }

    const itemExpand = (selectedParentIndex:number) => {
        let tempMenuOptions = [...menuOptions];
        tempMenuOptions[selectedParentIndex].Expand = !tempMenuOptions[selectedParentIndex].Expand;
        setMenuOptions(tempMenuOptions);
    }

    return (
        <>
            <div style={styles.backdrop} onClick={props.closeDialog}></div>
            <div style={styles.dialog}>        
                <div style={styles.title}>{props.title}</div>
                <div style={styles.container} className={"selector"}>
                    {menuOptions.map((menuOption, parentIndex) => 
                        <div key={parentIndex}>
                            <div style={styles.labelWrapper} onClick={() => itemExpand(parentIndex)}>
                                {menuOption.Label}
                                <Dropdown style={styles.labelIcon} />
                            </div>
                            <div style={menuOption.Expand ? styles.expand : styles.minimize}>
                                {menuOption.Options.map((option, childIndex) => 
                                    <div key={childIndex} style={styles.optionWrapper} onClick={() => itemChecked(parentIndex, childIndex, !option.Checked)}>                                                    
                                        <input type="checkbox" checked={option.Checked} style={option.Checked ? styles.checked : styles.unChecked} readOnly/>
                                        <div style={styles.option}>
                                            {option.PrimaryOption}    
                                            <div style={styles.secondaryOption}>{option.SecondaryOption}</div> 
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>                                                                            
                    )}
                </div>
                <div style={styles.buttonWrapper}>
                    <Button style={styles.button} onClick={() => props.closeDialog()}>Cancel</Button>
                    <Button style={{...styles.button, ...styles.button.select}} onClick={() => props.getMenuSelected(menuOptions)}>Select</Button>                    
                </div>
            </div> 
        </>       
    );
}