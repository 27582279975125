export const CommonTexts = {
    apiBaseUrl: "https://api.tuteclass.com/api/", 
    // apiBaseUrl: "http://localhost:7542/api/"    
}

export const HeaderTexts = {    
    heading: "The Wisdom Pathway",    
    desktopText: "Tuteclass is an application that caters useful information and services for the learn force of Sri Lanka who are looking for good opportunities in higher studies including degrees and diplomas of different streams. It is a virtual platform which allows quick access to the accurate information that you need and guide you in achieving your higher education goals.",
    mobileText: "TuteClass is an application that provide valuable resources and services for teachers and students in Sri Lanka. The goal of the TuteClass is to bring distributed and not easily accessible information, resources and services to one digital platform and make easily accessible.",        
    defaultAvatar: process.env.PUBLIC_URL + "/images/default_avatar.jpg"    
}

export const FooterTexts = {
    address: "Bandaranayake Mawatha, Moratuwa, Colombo 11.",
    email: "info@clipess.com",
    telephone: "+94772043480",    
    copyright: "© 2022 CLIPESS (PRIVATE) LIMITED. ALL RIGHTS RESERVED"
}

export const LoginTexts = {
    loginError: "The username or password is incorrect"
}

export const WhatsAppTexts = {    
    defaultAvatar: process.env.PUBLIC_URL + "/images/default_avatar.jpg"    
}

export const TeacherTexts = {    
    defaultTeacherAd: process.env.PUBLIC_URL + "/images/default_teacher_ad.jpg"            
}