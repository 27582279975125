import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { StyleContext } from "../../../providers/StyleContextProvider";
import { DialogInput } from "./DialogInput";
import { MenuOption } from "../../../models/MenuOption";
import { MultySelectMultyMenuDialog } from "../dialogs/MultySelectMultyMenuDialog";
import { InputValidation } from "../../../models/InputValidation";
import { MultySelectMultyMenuFilterDialog } from "../dialogs/MultySelectMultyMenuFilterDialog";

interface Props {
    label: string;    
    menuOptions: MenuOption[];
    selectedOptionIds?: string;
    setSelectedLabels?(labelIds?: string): void;
    setSelectedOptions(optionIds?: string): void;
    searchEnabled?: boolean;
    validation?: InputValidation;
    setValidation?(validation: InputValidation): void;
}

export const MultySelectMultyMenuInput: React.FC<Props> = (props) => {
    const styleContext = useContext(StyleContext);    
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("input"));
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [menuOptions, setMenuOptions] = useState<MenuOption[]>(props.menuOptions);
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    
    useEffect(() => {        
        setStyles(styleContext.getComponentStyle("input"));          
    }, [isMobile]);

    useEffect(() => {        
        if(props.selectedOptionIds && props.selectedOptionIds !== "") {
            setInitialSelects();
        }          
    }, []);

    const setInitialSelects = () => {        
        let selectedOptionIdList: string[] = props.selectedOptionIds!.split(",");
        let selectedOptionList: string[] = [];        
        props.menuOptions.forEach((menuOption, menuIndex) => {
            menuOption.Options.forEach((option, optionIndex) => {
                if(selectedOptionIdList.includes(option.Value.toString())) {
                    props.menuOptions[menuIndex].Expand = true;
                    props.menuOptions[menuIndex].Options[optionIndex].Checked = true;                                                                           
                    selectedOptionList.push(option.PrimaryOption);
                }
            });
        });        
        setSelectedOptions(selectedOptionList);
        setMenuOptions(props.menuOptions);
    }
    
    const getMenuSelected = (resultMenuOptions: MenuOption[]) => { 
        let selectedOptionIds: string = "";
        let selectedLabelIds: number[] = [];
        let tempSelectedOptions: string[] = [];
        resultMenuOptions.forEach((menuOption) => {            
            menuOption.Options.forEach((option) => {
                if(option.Checked) {
                    selectedOptionIds = selectedOptionIds + option.Value + ",";
                    tempSelectedOptions.push(option.PrimaryOption);
                    if(!selectedLabelIds.includes(menuOption.LabelId)) {
                        selectedLabelIds.push(menuOption.LabelId);
                    }
                }
            });            
        }); 
        selectedOptionIds = selectedOptionIds.substring(0, selectedOptionIds.length - 1);
        props.setSelectedOptions(selectedOptionIds);
        if(props.setSelectedLabels) {
            props.setSelectedLabels(selectedLabelIds.join(","));
        }
        setMenuOptions(resultMenuOptions);
        setSelectedOptions(tempSelectedOptions);
        setOpenDialog(false);
        if(props.setValidation) {
            props.setValidation({});
        }        
    }

    const resetOptions = () => {        
        setSelectedOptions([]);
        let tempMenuOptions = [...menuOptions];
        tempMenuOptions.forEach(menuOption => menuOption.Options.forEach(option => {option.Checked = false}));
        setMenuOptions(tempMenuOptions);        
        props.setSelectedOptions();
        if(props.setSelectedLabels) {
            props.setSelectedLabels();
        }
    }

    return (
        <div style={styles}>                
            <div style={styles.inputWrapper} onClick={() => setOpenDialog(true)}>
                <DialogInput values={selectedOptions} validation={props.validation} onReset={resetOptions}>
                    {props.label}
                </DialogInput>
            </div>                          
            {openDialog && (props.searchEnabled ? <MultySelectMultyMenuFilterDialog title={props.label} menuOptions={menuOptions} getMenuSelected={getMenuSelected} closeDialog={() => setOpenDialog(false)}/> : 
            <MultySelectMultyMenuDialog title={props.label} menuOptions={menuOptions} getMenuSelected={getMenuSelected} closeDialog={() => setOpenDialog(false)}/>)}            
        </div>
    )
}