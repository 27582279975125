import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { StyleContext } from '../../../providers/StyleContextProvider';
import { Button } from '../Button';
import { merge } from 'react-merge';
import { MenuOption } from '../../../models/MenuOption';

interface Props { 
    styles?: any;        
    title: string;     
    menuOptions: MenuOption[];    
    getMenuSelected(menuOptions: MenuOption[]): void;    
    closeDialog(): void;    
};

export const MultySelectMultyMenuFilterDialog: React.FC<Props> = (props) => {    
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("dialog"));
    const [menuOptions, setMenuOptions] = useState<MenuOption[]>(props.menuOptions); 
    const [filteredMenuOptions, setFilteredMenuOptions] = useState<MenuOption[]>(props.menuOptions);     
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });

    useEffect(() => {           
        let style = styleContext.getComponentStyle("dialog");
        let extendedStyle = merge(style, props.styles);
        setStyles(extendedStyle);
    }, [isMobile]);

    const itemChecked = (selectedLabelId: number, selectedOptionId: number, check: boolean) => {        
        let tempMenuOptions = [...menuOptions];
        tempMenuOptions.find(menuOption => menuOption.LabelId === selectedLabelId)!.Options.find(option => option.Value === selectedOptionId)!.Checked = check;                   
        setMenuOptions(tempMenuOptions);

        let tempFilteredMenuOptions = [...filteredMenuOptions];
        tempFilteredMenuOptions.find(menuOption => menuOption.LabelId === selectedLabelId)!.Options.find(option => option.Value === selectedOptionId)!.Checked = check;                   
        setFilteredMenuOptions(tempFilteredMenuOptions);
    }

    function filterOptions(keyword: string) {
        keyword = keyword.trim();        
        let filteredOptions = menuOptions.map(menuOption => ({
            LabelId: menuOption.LabelId,
            Label: menuOption.Label,
            Expand: true,            
            Options: menuOption.Options.filter(option =>
                option.PrimaryOption.toLowerCase().includes(keyword.toLowerCase()) ||
                option.SecondaryOption && option.SecondaryOption.toLowerCase().includes(keyword.toLowerCase())
            ),
        }));     
        filteredOptions = filteredOptions.filter(menuOption => menuOption.Options.length > 0);   
        setFilteredMenuOptions(filteredOptions);
    }

    return (
        <>
            <div style={styles.backdrop} onClick={props.closeDialog}></div>
            <div style={styles.dialog}>        
                <div style={styles.title}>{props.title}</div>
                <input type="text" style={styles.search} placeholder={"Search"} onChange={(e) => filterOptions(e.target.value)}/>
                <div style={styles.container} className={"selector"}>
                    {filteredMenuOptions.map((menuOption, parentIndex) => 
                        <div key={parentIndex}>
                            <div style={styles.searchLabelWrapper}>
                                {menuOption.Label}                                
                            </div>
                            <div style={styles.optionContainer}>
                                {menuOption.Options.map((option, childIndex) => 
                                    <div key={childIndex} style={styles.optionWrapper} onClick={() => itemChecked(menuOption.LabelId, option.Value, !option.Checked)}>                                                    
                                        <input type="checkbox" checked={option.Checked} style={option.Checked ? styles.checked : styles.unChecked} readOnly/>
                                        <div style={styles.option}>
                                            {option.PrimaryOption}    
                                            <div style={styles.secondaryOption}>{option.SecondaryOption}</div> 
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>                                                                            
                    )}
                </div>
                <div style={styles.buttonWrapper}>
                    <Button style={styles.button} onClick={() => props.closeDialog()}>Cancel</Button>
                    <Button style={{...styles.button, ...styles.button.select}} onClick={() => props.getMenuSelected(menuOptions)}>Select</Button>                    
                </div>
            </div> 
        </>       
    );
}