import React, { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { StyleContext } from "../providers/StyleContextProvider";
import { Header } from "../components/shared/Header";
import { Footer } from "../components/shared/Footer";
import { MessageDialog } from "../components/shared/dialogs/MessageDialog";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Login } from "../components/login/Login";
import { ForgotPassword } from "../components/login/ForgotPassword";
import { ResetPassword } from "../components/login/ResetPassword";

export const LoginPage: React.FC = () => {    
    let params = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();  
    const styleContext = useContext(StyleContext);    
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("loginPage"));    
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" }); 
    const [isError, setIsError] = useState<boolean>(false);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [dialogMessage, setDialogMessage] = useState<string>();
    const stage = params.stage;
    const callbackUrl: string | null = searchParams.get("callbackUrl");
    const callbackMessage: string | null = searchParams.get("callbackMessage"); 

    useEffect(() => { 
        window.scrollTo(0, 0);   
        setStyles(styleContext.getComponentStyle("loginPage"));                                                
    }, [isMobile]);
    
    const openMessageDialog = (message: string) => {
        setDialogMessage(message);
        setOpenDialog(true);
    }

    const handleDialogOk = () => {  
        if(!isError) {
            handleChangeState("login");
        }      
        setOpenDialog(false);
    }

    const handleChangeState = (stage: string) => {
        if(callbackUrl !== null) {
          searchParams.set("callbackUrl", callbackUrl);
        }
        if(callbackMessage !== null) {
          searchParams.set("callbackMessage", callbackMessage);
        }
        navigate("/account/" + stage + `?${searchParams}`);
    }

    return (
        <>
            <Header styleSheet={"loginHeader"} />            
            {stage === "login" && <Login styles={styles} callbackUrl={callbackUrl} callbackMessage={callbackMessage} setError={setIsError} setStage={handleChangeState} openMessageDialog={openMessageDialog} />}            
            {stage === "forgotPassword" && <ForgotPassword styles={styles} setError={setIsError} setStage={handleChangeState} openMessageDialog={openMessageDialog} />}
            {stage === "resetPassword" && <ResetPassword styles={styles} email={searchParams.get("email")!} code={searchParams.get("code")!} setError={setIsError} setStage={handleChangeState} openMessageDialog={openMessageDialog} />}
            <Footer />
            {openDialog && <MessageDialog styles={{...styles.dialog, ...(isError && styles.dialogError)}} title={isError ? "Error Occurred" : "Successful"} message={dialogMessage!} handleOk={handleDialogOk} />}
        </>        
    )
}