import { Color } from "../enums/Color";

export const input = {
    mobile: {                       
        wrapper: {
            position: "relative",
            display: "flex",
            flexDirection: "row",
            width: "100%",
            margin: "30px auto 0 auto"            
        },
        label: {
            position: "absolute",
            pointerEvents: "none",
            transformOrigin: "top left",
            transition: "200ms cubic-bezier(0, 0, 0.2, 1) 0ms", 
            transform: "translate(0, 5px) scale(1)",
            color: Color.Black,
            fontSize: "16px",            
            lineHeight: "0",
            left: "12px",           
            focus: {
                transform: "translate(-2px, -10px) scale(0.8)",
                color: Color.Green,
                fontSize: "14px",
                fontWeight: "700"
            },
            invalid: {
                color: Color.Red
            }
        },
        input: {            
            width: "-webkit-fill-available",                                    
            paddingLeft: "10px",
            fontSize: "16px",
            background: "transparent",
            border: "none",
            borderBottom: "1px solid " + Color.Black,
            overflow: "hidden",
            cursor: "pointer",
            resize: "none",
            short: {
                paddingBottom: "5px"
            },
            long: {
                paddingBottom: "10px"
            },
            valid: {
                borderBottom: "1px solid " + Color.Green
            },
            invalid: {
                borderBottom: "1px solid " + Color.Red
            }
        },
        textarea: {
            height: "162px",
            resize: "none",
            overflowY: "scroll"
        },
        icon: {
            marginLeft: "-35px",
            marginTop: "5px",
            height: "7px"
        },
        eyeIcon: {
            width: "4.17vw",
            height: "4.17vw",
            fill: Color.SharpAsh,
            marginLeft: "-5.17vw"
        },
        resetIcon: {
            marginLeft: "-45px",
            cursor: "pointer"
        },
        switch: {
            backgroundColor: Color.LightGray,
            borderRadius: "69.44vw",
            cursor: "pointer",
            display: "flex",
            height: "5vw",
            width: "11.11vw",
            padding: "0.56vw",
            margin: "auto",
            marginRight: "unset",
            WebkitTapHighlightColor: "transparent",
            checked: {
                backgroundColor: Color.SharpGreen
            }
        },
        switchCircle: {
            height: "5vw",
            width: "5vw",
            borderRadius: "50%",
            backgroundColor: Color.White,
            checked: {
                marginLeft: "auto",
                backgroundColor: Color.Green
            }
        },
        invalidMessage: {
            color: Color.Red,
            fontSize: "14px",
            fontWeight: "700",
            paddingLeft: "10px"
        }
    },
    desktop: {
        wrapper: {
            margin: "42px auto 0 auto"            
        },
        label: {
            focus: {
                fontSize: "16px"
            },
            blur: {
                fontSize: "18px"
            }
        },
        input: {
            fontSize: "18px"
        },
        eyeIcon: {
            width: "1.05vw",
            height: "1.05vw",
            marginLeft: "-1.75vw"            
        },
        switch: {
            borderRadius: "17.50vw",
            height: "1.04vw",
            width: "2.08vw",
            padding: "0.15vw 0.2vw"
        },
        switchCircle: {
            height: "1.04vw",
            width: "1.04vw"
        }
    }
}