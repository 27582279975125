import { Color } from "../enums/Color"
import { Font } from "../enums/Font"

export const loginHeader = {    
    mobile: {
        wrapper: {
            background: Color.DarkGreen, 
            backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/sub_header.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 82%",
            backgroundPosition: "center bottom",        
            margin: "0",            
            width: "100%",
            height: "224px",        
            opacity: "1"
        },
        title: {            
            paddingLeft: "4%", 
            textAlign: "left" as "left",                
            fontSize: "36px",        
            fontFamily: Font.Advanced,        
            letterSpacing: "0px",
            color: Color.AquaGreen,            
            cursor: "pointer",
            opacity: "1"
        },
        heading: {
            display: "none"
        },
        text: {  
            display: "none",
            position: "relative" as "relative", 
            left: "50%",         
            transform: "translate(-50%, 0%)",
            width: "90%",
            height: "100px",                              
            textAlign: "center" as "center",            
            fontSize: "4vw",                      
            letterSpacing: "0px",
            color: Color.White,
            opacity: "1"
        }
    },
    desktop: {        
        wrapper: {               
            backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/main_header.jpg)",
            backgroundSize: "100% 160%",                                                      
            height: "320px"            
        },
        title: {                
            paddingTop: "16px",
            paddingLeft: "50px",                            
            fontSize: "46px"
        },
        text: {
            display: "block",
            top: "20%",
            width: "70%",
            height: "80px",                                          
            fontSize: "18px"
        }
    }
}
