import React from 'react';

interface Props {    
    type?: "button" | "submit" | "reset" | undefined;
    style: any;
    disabled?: boolean;
    children?: React.ReactNode;    
    onClick?(params: any): void;
};

export const Button: React.FC<Props> = (props) => {
    return (
        <button 
            type={props.type} 
            style={props.style} 
            disabled={props.disabled} 
            onClick={props.onClick}
        >
            {props.children}
        </button>
    );
}