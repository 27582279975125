import React, { useState } from 'react';
import { Button } from '../shared/Button';
import { TextInput } from '../shared/inputs/TextInput';
import { ResponseModel } from '../../models/ResponseModel';
import { forgotPassword } from '../../services/AccountService';
import { LoginPageLoader } from '../loaders/LoginPageLoader';

interface Props {
    styles: any;     
    setError(isError: boolean): void;
    setStage(stage: string): void;
    openMessageDialog(message: string): void;
}

export const ForgotPassword: React.FC<Props> = (props) => {    
    const [email, setEmail] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);    
    const styles = props.styles;

    async function submitEmail(event: React.FormEvent<HTMLFormElement>) {        
        event.preventDefault();
        setLoading(true);
        await forgotPassword(email)
        .then((response: ResponseModel) => {  
            setLoading(false);          
            props.setError(!response.Status);
            props.openMessageDialog(response.Message);            
        }) 
        .catch((error) => { 
            setLoading(false);           
            //toErrorPage(error);
        });
    }
    
    return (
        <form style={styles.form} onSubmit={submitEmail}>
            <h1 style={styles.title}>Forgot Password?</h1>
            <p style={styles.subTitle}>Please enter your email address.</p>
            {loading ? <LoginPageLoader/> : <>
                <TextInput type="text" name="email" value={email} label="Email" onChange={(e) => setEmail(e.target.value)} />
                <Button type="submit" style={styles.submitButton}>{"SEND ME THE LINK"}</Button>
            </>}
            <div style={styles.rowWrapper}>
                <Button style={{...styles.redirectButton, ...styles.redirectButton.left}} onClick={() => {props.setStage("register")}}>Register</Button>
                <Button style={{...styles.redirectButton, ...styles.redirectButton.right}} onClick={() => {props.setStage("login")}}>Login</Button>
            </div>
        </form>
    )
}