import { useContext, useEffect, useRef, useState } from "react";
import { StyleContext } from "../providers/StyleContextProvider";
import { AuthContext } from "../providers/AuthContextProvider";
import { Admin } from "../models/Admin";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { WhatsAppContact } from "../models/WhatsAppContact";
import { getWhatsAppContacts, getWhatsAppMessages, sendWhatsAppMediaMessage, sendWhatsAppPromotionPost, sendWhatsAppTemplateTextMessage, sendWhatsAppTextMessage } from "../services/WhatsAppService";
import { WhatsAppMessage } from "../models/WhatsAppMessage";
import { ListLoader } from "../components/loaders/ListLoader";
import { NoData } from "../components/shared/NoData";
import { WhatsAppTexts as Texts } from "../helpers/LayoutTexts";
import { Button } from "../components/shared/Button";
import { ReactComponent as Send } from "../assets/svgs/send.svg";
import { ReactComponent as Plus } from "../assets/svgs/plus.svg";
import { ReactComponent as Upload } from "../assets/svgs/upload.svg";
import { ReactComponent as Close } from "../assets/svgs/close-circle.svg";
import { ResponseModel } from "../models/ResponseModel";
import { WhatsAppSendMessage } from "../models/WhatsAppSendMessage";

export const WhatsAppPage: React.FC = () => {
    const navigate = useNavigate();
    const styleContext = useContext(StyleContext);
    const authContext = useContext(AuthContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("whatsAppPage"));  
    const admin: Admin | null = authContext.getLoggedAdmin();     
    const [contactLoading, setContactLoading] = useState<boolean>(true);
    const [messageLoading, setMessageLoading] = useState<boolean>(true);    
    const [contacts, setContacts] = useState<WhatsAppContact[]>([]);
    const [filteredContacts, setFilteredContacts] = useState<WhatsAppContact[]>([]);
    const [selectedContact, setSelectedContact] = useState<WhatsAppContact>();
    const [messages, setMessages] = useState<WhatsAppMessage[]>([]);
    const fileInputRef = useRef<HTMLInputElement>(null); 
    const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
    const [filePreviewUrl, setFilePreviewUrl] = useState<string>();    
    const [isPromotionPost, setIsPromotionPost] = useState<boolean>(false);
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const [searchKeyword, setSearchKeyword] = useState<string>("");
    const [replyText, setReplyText] = useState<string>("");
    const [hoveredIndex, setHoveredIndex] = useState<number>(-1);
    const timerRef = useRef<NodeJS.Timeout | null>(null);
    
    useEffect(() => {                        
        if(admin === null) {            
            navigate('/account/login');
        }  
    });

    useEffect(() => {  
        window.scrollTo(0, 0);
        getContactList(); 
        timerRef.current = setInterval(() => {
            getContactList();            
        }, 120000);
        return () => clearInterval(timerRef.current!);                                   
    }, []);

    useEffect(() => {        
        setStyles(styleContext.getComponentStyle("whatsAppPage")); 
    }, [isMobile]);

    async function getContactList() {        
        await getWhatsAppContacts()
        .then((contactList: WhatsAppContact[]) => {
            setContacts(contactList);
            setFilteredContacts(contactList);              
            setContactLoading(false);
        })
        .catch((error) => {            
            // toErrorPage(error);
        });
    }

    async function getContactMessages(phoneNumber: string) {
        setMessageLoading(true);
        await getWhatsAppMessages(phoneNumber)
        .then((contactMessages: WhatsAppMessage[]) => {
            setMessages(contactMessages);
            setMessageLoading(false);
        })
        .catch((error) => {            
            // toErrorPage(error);
        });
    }

    const filterContacts = (keyword: string) => {        
        setSearchKeyword(keyword);
        keyword = keyword.toLowerCase().trim();
        let tempContacts = contacts.filter(x => (x.PhoneNumber.toLowerCase().includes(keyword) || (x.ContactName && x.ContactName.toLowerCase().includes(keyword))));        
        setFilteredContacts(tempContacts);        
    }

    const selectContact = (contact: WhatsAppContact) => {
        setSelectedContact(contact);
        let tempContacts = [...contacts];
        tempContacts.filter(x => x.PhoneNumber === contact.PhoneNumber)[0].UnreadMessageCount = 0;
        setContacts(tempContacts);
        getContactMessages(contact.PhoneNumber);
    }

    async function sendTextMessage(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setMessageLoading(true);     
        let sendMessage: WhatsAppSendMessage = {
            Type: "text",
            PhoneNumber: selectedContact!.PhoneNumber,
            ContactName: selectedContact!.ContactName,
            Text: replyText
        };        
        await sendWhatsAppTextMessage(sendMessage)
        .then((response: ResponseModel) => {
            if(response.Status) {
                setContacts(response.Data.Contacts);
                setFilteredContacts(response.Data.Contacts);
                setMessages(response.Data.Messages);
                setReplyText("");
            }                
            setMessageLoading(false);                
        }) 
        .catch((error) => {                        
            //toErrorPage(error);
        });
    }

    async function sendTemplateTextMessage(event: React.FormEvent<HTMLFormElement>, templateName: string) {
        event.preventDefault();
        setMessageLoading(true);
        let sendMessage: WhatsAppSendMessage = {
            Type: "text",
            PhoneNumber: selectedContact!.PhoneNumber,
            ContactName: selectedContact!.ContactName,
            TemplateName: templateName
        };
        await sendWhatsAppTemplateTextMessage(sendMessage)
        .then((response: ResponseModel) => {
            if(response.Status) {
                setContacts(response.Data.Contacts);
                setFilteredContacts(response.Data.Contacts);
                setMessages(response.Data.Messages);
            }                
            setMessageLoading(false);                
        }) 
        .catch((error) => {                        
            //toErrorPage(error);
        }); 
    }

    const handleFileButtonClick = () => {        
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {        
        const file = event.target.files?.[0];
        if (file) {            
            setSelectedFile(file);
            setFilePreviewUrl(URL.createObjectURL(file));                            
        }
    };

    const removeFileUpload = () => {
        setSelectedFile(undefined);
        setFilePreviewUrl(undefined);
    }

    async function sendMediaMessage (event: React.FormEvent<HTMLFormElement>) { 
        event.preventDefault();       
        setMessageLoading(true);
        let sendMessage: WhatsAppSendMessage = {
            Type: "image",
            PhoneNumber: selectedContact!.PhoneNumber,
            ContactName: selectedContact!.ContactName
        };         
        const messageString = JSON.stringify(sendMessage);
        const formData = new FormData();
        formData.append('message', messageString);  
        formData.append('file', selectedFile!);
        if(isPromotionPost)
        {        
            await sendWhatsAppPromotionPost(formData)
            .then((response: ResponseModel) => {
                setSelectedFile(undefined);
                setFilePreviewUrl(undefined);
                setIsPromotionPost(false);
                if(response.Status) {                    
                    setContacts(response.Data.Contacts);
                    setFilteredContacts(response.Data.Contacts);
                    setMessages(response.Data.Messages);
                }                
                setMessageLoading(false);                
            })
            .catch((error) => {            
                //toErrorPage(error);
            });                       
        }
        else
        {
            await sendWhatsAppMediaMessage(formData)
            .then((response: ResponseModel) => {
                setSelectedFile(undefined);
                setFilePreviewUrl(undefined);
                if(response.Status) {
                    setContacts(response.Data.Contacts);
                    setFilteredContacts(response.Data.Contacts);
                    setMessages(response.Data.Messages);
                }                
                setMessageLoading(false);                
            })
            .catch((error) => {            
                //toErrorPage(error);
            }); 
        }
    }

    const sendPromotionPost = () => {
        setIsPromotionPost(true);
        handleFileButtonClick();
    }

    return (
        <div style={styles}>
            <div style={styles.contactListWrapper}>
                <div style={styles.searchWrapper}>                    
                    <input 
                        type="text"
                        placeholder="Search"
                        value={searchKeyword}
                        onChange={(e) => filterContacts(e.target.value)}
                        style={styles.searchInput}
                    />
                </div>
                {contactLoading ? <ListLoader /> : 
                <div style={styles.contact.container}>
                    {contacts.length === 0 ? <NoData /> : 
                    <>
                        {filteredContacts.map((contact, index) =>
                            <div 
                                key={index} 
                                style={{...styles.contact.card, ...(hoveredIndex === index && styles.contact.card.hovered), ...(selectedContact?.PhoneNumber === contact.PhoneNumber && styles.contact.card.selected) }}
                                onMouseEnter={() => setHoveredIndex(index)}
                                onMouseLeave={() => setHoveredIndex(-1)}
                                onClick={() => selectContact(contact)}
                            >                                
                                <img src={Texts.defaultAvatar} style={styles.contact.logo}></img>                                
                                <div>
                                    <div style={styles.contact.name}>{contact.ContactName}</div>
                                    <div style={styles.contact.number}>{contact.PhoneNumber}</div>                                    
                                </div> 
                                {(contact.UnreadMessageCount > 0) && <div style={styles.contact.unreadCount}>{contact.UnreadMessageCount}</div>}
                            </div>                     
                        )} 
                    </>}     
                </div>}
            </div>
            <div style={styles.messageWrapper}>
                {selectedContact && <>
                    <div style={styles.selectedContact}>
                        <img src={Texts.defaultAvatar} style={styles.contact.logo}></img>                                
                        <div>
                            <div style={styles.contact.name}>{selectedContact.ContactName}</div>
                            <div style={styles.contact.number}>{selectedContact.PhoneNumber}</div>                                    
                        </div>
                    </div> 
                    <div style={styles.messageContent}>
                        <div>
                            {(messages.length > 0) ? <ul style={styles.messageList}>
                                {messages.map((message, index) =>                           
                                    <div 
                                        key={index} 
                                        style={{...styles.message, ...(message.IsSendMessage && styles.message.sent), ...(message.IsReceiveMessage && styles.message.receive)}}
                                    >
                                        {message.MessageType === "text" && <span>{message.Text}</span>}
                                        {message.MessageType === "image" && <img src={message.ImageUrl} alt="WhatsApp Image" style={styles.message.image} />}
                                        {message.MessageType === "template" && <div style={styles.message.template} >
                                            {message.ImageUrl && <img src={message.ImageUrl} alt="WhatsApp Image" style={styles.message.image} />}
                                            <span>{message.Text}</span>
                                        </div>}
                                    </div>                            
                                )}                        
                                {selectedFile && <div style={styles.message.fileWrapper}>
                                    <Close style={styles.message.closeIcon} onClick={removeFileUpload} />
                                    <img src={filePreviewUrl} alt="Uploaded File" style={styles.message.file} />                            
                                    <Button type="button" style={styles.message.fileUploadButton} onClick={sendMediaMessage}>
                                        <Upload style={styles.message.uploadIcon} />{"Upload File"}
                                    </Button>
                                </div>}
                                <input type="file" accept="image/*" onChange={handleFileUpload} style={{display: 'none'}} ref={fileInputRef} />
                                {messageLoading && <div style={{...styles.message, ...styles.message.sent}}>{"tuteclass.com is typing..."}</div>}
                            </ul>
                            : <div style={styles.messageLoading}>{"tuteclass.com is loading..."}</div>}
                        </div>
                    </div>
                    <div style={styles.form.wrapper}>
                        <div>
                            <Button type="button" style={{...styles.form.templateButton, ...styles.form.templateButton.promotion}} onClick={(e) => sendTemplateTextMessage(e, "promotion_introduction")}>
                                {"Promotion"}
                            </Button>
                            <Button type="button" style={{...styles.form.templateButton, ...styles.form.templateButton.post}} onClick={sendPromotionPost}>
                                {"Promotion Post"}
                            </Button>
                            <Button type="button" style={{...styles.form.templateButton, ...styles.form.templateButton.facebook}} onClick={(e) => sendTemplateTextMessage(e, "like_facebook_page")}>
                                {"Like Facebook"}
                            </Button>
                            <Button type="button" style={{...styles.form.templateButton, ...styles.form.templateButton.comment}} onClick={(e) => sendTemplateTextMessage(e, "post_as_comment")}>
                                {"Post as Comment"}
                            </Button>
                            <Button type="button" style={{...styles.form.templateButton, ...styles.form.templateButton.more}} onClick={(e) => sendTemplateTextMessage(e, "more_details")}>
                                {"More Details"}
                            </Button>
                            <Button type="button" style={{...styles.form.templateButton, ...styles.form.templateButton.intro}} onClick={(e) => sendTemplateTextMessage(e, "tuteclass_intro")}>
                                {"tuteclass Intro"}
                            </Button>
                            <Button type="button" style={{...styles.form.templateButton, ...styles.form.templateButton.location}} onClick={(e) => sendTemplateTextMessage(e, "location_request")}>
                                {"Location Request"}
                            </Button>
                        </div>
                        <form style={styles.form.form} onSubmit={sendTextMessage}>                             
                            <Button type="button" style={{...styles.form.fileButton, ...(!selectedContact.SessionWindowEnabled && styles.disabled)}} onClick={handleFileButtonClick} disabled={!selectedContact.SessionWindowEnabled}>
                                <Plus style={styles.form.fileIcon} />
                            </Button>                  
                            <input 
                                type="text"
                                placeholder="Reply..."
                                value={replyText}
                                onChange={(e) => setReplyText(e.target.value)}
                                style={{...styles.form.input, ...(!selectedContact.SessionWindowEnabled && styles.disabled)}}
                                disabled={!selectedContact.SessionWindowEnabled}
                            />                                        
                            <Button type="submit" style={{...styles.form.sendButton, ...(!selectedContact.SessionWindowEnabled && styles.disabled)}} onClick={sendTextMessage} disabled={!selectedContact.SessionWindowEnabled}>
                                <Send style={styles.form.sendIcon} />
                            </Button>
                        </form>
                    </div>
                </>}
            </div>
        </div>
    )
}