import React, { useContext, useEffect, useState } from 'react';
import { Option } from '../../models/Option';
import { DropdownItem } from './DropdownItem';
import { ReactComponent as Icon } from '../../assets/svgs/drop_down.svg';
import { ReactComponent as Close } from "../../assets/svgs/close-circle-ash.svg"; 
import { StyleContext } from '../../providers/StyleContextProvider';
import { useMediaQuery } from 'react-responsive';
import { merge } from 'react-merge';

interface Props {
    styles?: any;
    label: string;
    options: Option[];
    initialValue?: string;
    getSelectedValue(selectedValue: any): void;
}

export const Select: React.FC<Props> = (props) => {
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("select")); 
    const [primaryLabel, setPrimaryLabel] = useState<string>(props.label);
    const [secondaryLabel, setSecondaryLabel] = useState<string>();
    const [options] = useState<Option[]>(props.options);
    const [filteredOptions, setFilteredOptions] = useState<Option[]>(props.options);    
    const [hovered, setHovered] = useState<boolean>(false);
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const [resetEnable, setResetEnable] = useState<boolean>(false);

    useEffect(() => {
        if(props.initialValue) {
            setInitialValue();
        }
    }, []);

    useEffect(() => {           
        let style = styleContext.getComponentStyle("select");
        let extendedStyle = merge(style, props.styles);  
        setStyles(extendedStyle);        
    }, [isMobile]);

    const filterOptions = (keyword: string) => {
        keyword = keyword.trim();        
        let tempFilteredOptions = options.filter(option =>
            option.PrimaryOption.toLowerCase().includes(keyword.toLowerCase()) ||
            option.SecondaryOption && option.SecondaryOption.toLowerCase().includes(keyword.toLowerCase())
        );          
        setFilteredOptions(tempFilteredOptions);
    }

    const setInitialValue = () => {                        
        let initialOption = props.options.find(x => x.Value.toString() === props.initialValue);        
        if(initialOption) {
            setPrimaryLabel(initialOption.PrimaryOption);
            setSecondaryLabel(initialOption.SecondaryOption);
        }
    }

    const selectOption = (option: Option) => {
        setPrimaryLabel(option.PrimaryOption);
        setSecondaryLabel(option.SecondaryOption);
        props.getSelectedValue(option.Value);
        setResetEnable(true);
    }

    const resetOption = (event: React.MouseEvent) => {
        event.stopPropagation();        
        setPrimaryLabel(props.label);
        setSecondaryLabel(undefined);
        props.getSelectedValue(undefined);
        setResetEnable(false);
    }

    return (
        <div
            style={styles.wrapper}
            onMouseEnter={() => {setHovered(true);}}
            onMouseLeave={() => {setHovered(false);}} 
        >
            <div style={styles.labelWrapper}>
                <div>
                    <div style={styles.label}>{primaryLabel}</div>
                    {secondaryLabel && <div style={styles.label}>{secondaryLabel}</div>}
                </div>
                <Icon style={{...styles.labelIcon, ...(hovered && styles.labelIcon.hovered)}}/>
                {resetEnable && <Close style={styles.resetIcon} onClick={(e) => resetOption(e)} />}
            </div>
            <div style={hovered ? styles.hover : styles.leave}>
                <input type="text" style={styles.search} placeholder={"Search"} onChange={(e) => filterOptions(e.target.value)}/>
                <div style={styles.container} className={"selector"}>
                    {filteredOptions.map((option, index) => 
                        <div key={index}>                       
                            <DropdownItem styles={styles.optionWrapper} onClick={() => selectOption(option)}>
                                <div style={styles.primaryOption}>{option.PrimaryOption}</div>
                                {option.SecondaryOption && <div style={styles.secondaryOption}>{option.SecondaryOption}</div>}
                            </DropdownItem>  
                            <hr style={styles.bottomBorder} />  
                        </div>                                       
                    )}    
                </div>
            </div>
        </div>
    );
};

