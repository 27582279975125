import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { StyleContext } from '../../providers/StyleContextProvider';
import { HeaderTexts as Texts } from '../../helpers/LayoutTexts';

interface Props {
    styleSheet: string;
    heading?: string;    
}

export const Header: React.FC<Props> = (props) => {    
    const styleContext = useContext(StyleContext);    
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle(props.styleSheet));                     
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });     

    useEffect(() => {        
        setStyles(styleContext.getComponentStyle(props.styleSheet));                    
    }, [isMobile]);

    return (
        <div style={styles.wrapper}>                  
            <div style={styles.title}>{"TuteClass"}</div>           
            <h1 style={styles.heading}>{props.heading ? props.heading : Texts.heading}</h1> 
            <p style={styles.text}>{isMobile ? Texts.mobileText : Texts.desktopText}</p>            
        </div>
    );
}