import { Color } from "../enums/Color";

export const select = {
    mobile: {        
        wrapper: {
            marginTop: "2.02vh",
            marginLeft: "auto",
            zIndex: "3",
            WebkitTapHighlightColor: "transparent"
        },        
        labelWrapper: {
            display: "flex",
            flexDirection: "row" as "row",
            height: "4.05vh",
            paddingTop: "1.35vh",
            paddingRight: "1.35vh",            
            border: "2px solid " + Color.Green
        },
        label: {
            
        },
        labelIcon: {
            width: "3.33vw",
            height: "3.33vw",
            fill: Color.Black,
            transition: "transform .1s ease-in-out",
            hover: {
                transform: "rotate(180deg)"
            }
        },
        leave: {
            display: "none"
        },
        hover: {
            display: "block",
            position: "absolute",
            height: "31.62vh",
            WebkitOverflowScrolling: "touch",
            padding: "0 1.11vw 1.35vh",            
            backgroundColor: Color.White,
            boxShadow: "0 3px 1.35vh rgb(0 0 0 / 0.5)",
            borderRadius: "0 0 1.35vh 1.35vh",
            zIndex: 1
        },
        search: {
            display: "flex",
            height: "4.05vh",
            width: "90%",
            margin: "auto",
            marginTop: "1.35vh",
            fontSize: "4.44vw",
            paddingLeft: "1.35vh",
            color: Color.Black,
            border: "none",
            borderRadius: "4.16vw",
            backgroundColor: Color.LightAsh,
            icon: {
                width: "0.8vw",
                height: "auto",
                fill: Color.SharpAsh,
                position: "absolute" as "absolute",
                top: "7.5%",
                left: "32%"
            }
        },
        container: {
            height: "82%",
            overflow: "auto",
            marginTop: "1.38vw"
        },
        optionWrapper: {
            margin: "0 1.38vw",
            padding: "1.38vw",
            cursor: "pointer",
            position: "relative" as "relative",
            zIndex: 1,
            hover: {
                backgroundColor: Color.Green,
                margin: "0 1.38vw",
                borderRadius: "1.69vw",
            },
            leave: {
                backgroundColor: "unset"
            }
        },        
        primaryOption: {
            
        },
        secondaryOption: {

        },
        bottomBorder: {
            border: 0,
            height: "1px",
            width: "96%",
            background: Color.MiddleAsh,
            margin: "0 auto",
            zIndex: "1"
        }        
    },
    desktop: {        
        wrapper: {          
            marginTop: "0",
            paddingBottom: "15px"           
        },
        labelWrapper: {
            height: "unset",
            padding: "12px",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "10px"
        },
        label: {
            fontSize: "1.2vw"
        },
        labelIcon: {
            width: "0.8vw",
            height: "auto",
            margin: "0 0 0 12px"            
        },
        resetIcon: {
            margin: "0 0 0 auto"
        },
        hover: {
            width: "588px",
            height: "420px",
            padding: "10px",
            marginTop: "6px",            
            borderRadius: "10px"
        },
        search: {
            height: "36px",
            width: "-webkit-fill-available",            
            fontSize: "1vw",            
            borderRadius: "10px",
            margin: "0 0.36vw",
            paddingLeft: "0.8vw"
        },
        container: {
            marginTop: "0.36vw"
        },
        optionWrapper: {
            margin: "0 0.36vw",
            padding: "8px 0",
            hover: {
                margin: "0 0.36vw",
                borderRadius: "0.364vw"
            }
        },
        primaryOption: {            
            marginLeft: "16px"
        },
        secondaryOption: {
            marginLeft: "16px"
        },
        bottomBorder: {}
    }    
}