import React, { useContext, useEffect, useState } from 'react';
import { Option } from '../../../models/Option';
import { useMediaQuery } from 'react-responsive';
import { StyleContext } from '../../../providers/StyleContextProvider';
import { Button } from '../Button';
import { merge } from 'react-merge';

interface Props { 
    styles?: any;         
    title: string;    
    options: Option[];    
    getSelected(selectedOptions: Option[]): void;    
    closeDialog(): void;    
};

export const MultySelectFilterDialog: React.FC<Props> = (props) => {    
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("dialog"));
    const [options, setOptions] = useState<Option[]>(props.options);
    const [filteredOptions, setFilteredOptions] = useState<Option[]>(props.options);             
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });           

    useEffect(() => {   
        let style = styleContext.getComponentStyle("dialog");
        let extendedStyle = merge(style, props.styles);  
        setStyles(extendedStyle);                                                                  
    }, [isMobile]);

    const itemSelected = (value: number, checked: boolean) => {
        let tempOptions = [...options];        
        tempOptions.find(x => x.Value === value)!.Checked = checked;   
        setOptions(tempOptions);    
        
        let tempFilteredOptions = [...filteredOptions];        
        tempFilteredOptions.find(x => x.Value === value)!.Checked = checked;   
        setFilteredOptions(tempFilteredOptions); 
    }

    const filterOptions = (keyword: string) => {    
        keyword = keyword.trim();       
        let tempFilteredOptions = options.filter(option =>
            option.PrimaryOption.toLowerCase().includes(keyword.toLowerCase()) ||
            option.SecondaryOption && option.SecondaryOption.toLowerCase().includes(keyword.toLowerCase())
        );          
        setFilteredOptions(tempFilteredOptions);
    }

    return (
        <>
            <div style={styles.backdrop} onClick={props.closeDialog}></div>
            <div style={styles.dialog}>
                <div style={styles.title}>{props.title}</div>
                <input type="text" style={styles.search} placeholder={"Search"} onChange={(e) => filterOptions(e.target.value)}/>
                <div style={styles.container} className={"selector"}>
                    {filteredOptions.map((option, index) =>                                     
                        <div key={index} style={styles.optionWrapper} onClick={() => itemSelected(option.Value, !option.Checked)}>                                                    
                            <input type={"checkbox"} checked={option.Checked} style={option.Checked ? styles.checked : styles.unChecked} readOnly/>
                            <div style={styles.option}>
                                {option.PrimaryOption}    
                                {option.SecondaryOption && <div style={styles.secondaryOption}>{option.SecondaryOption}</div>}
                            </div>
                        </div>                    
                    )}    
                </div>
                <div style={styles.buttonWrapper}>
                    <Button style={styles.button} onClick={() => props.closeDialog()}>Cancel</Button>
                    <Button style={{...styles.button, ...styles.button.select}} onClick={() => props.getSelected(options)}>Select</Button>                    
                </div>
            </div> 
        </>       
    );
}