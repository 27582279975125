
export function validateEmail(email: string): boolean {
    const emailRegex = /\S+@\S+\.\S+/;    
    return emailRegex.test(email);
}

export const validatePhoneNumber = (number: string) => {
    //Valid numbers : +947********, 947********, +9407********, 9407********, 07********, 7********
    let regex = /^(?:\+94|94|\+940|940|0)?(7\d{8})$/;  
    return regex.test(number);  
}

export const getWhatsAppFormatNumber = (number: string) : string => {
    let whatsAppFormatNumber: string = "";
    let regex = /^(?:\+94|94|\+940|940|0)?(7\d{8})$/;  
    if(regex.test(number)) {
        whatsAppFormatNumber = "94" + number.substring(number.length - 9);
    }
    return whatsAppFormatNumber;
}

export const getSeoName = (value: string) => {    
    return value
    .toLowerCase()
    .replace(/[^\w\s-]/g, '') // Remove special characters
    .replace(/\s+/g, '-')     // Replace spaces with hyphens
    .trim();                  // Trim any leading/trailing spaces
}