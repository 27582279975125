import ContentLoader from 'react-content-loader';
import { Color } from '../../enums/Color';
import { useMediaQuery } from 'react-responsive';
import { merge } from 'react-merge';

const styles = {
    mobile: {        
        marginLeft: "5%",
        marginTop: "120px",
        card: {
            display: "inline-block",
            width: "100%",
            marginBottom: "8%"
        }        
    },
    desktop: {
        width: "44%",
        margin: "10% 0 0 30%",
        card: {            
            marginBottom: "6%"
        } 
    }
};

export const ListLoader : React.FC = () => {
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    var loaderStyles = styles.mobile;
    if (!isMobile) {
        let extendStyles = merge(styles.mobile, styles.desktop);
        loaderStyles = extendStyles;
    } 
    const loaderElements = new Array<any>();
    const loaderElementsCount = 8;
    for (let i = 0; i < loaderElementsCount; i++) {
        loaderElements.push(
            <div key={i} style={loaderStyles.card}>
                <ContentLoader
                    speed={1}
                    width={"100%"}
                    height={60}
                    backgroundColor={Color.LoaderBackground}
                    foregroundColor={Color.LoaderForeground}
                >
                    <rect x="2%" y="0" rx="25" ry="25" width="50" height="50" />
                    <rect x="20%" y="8" rx="2" ry="2" width="40%" height="10%" />
                    <rect x="20%" y="18" rx="4" ry="4" width="70%" height="20%" />
                    <rect x="20%" y="38" rx="2" ry="2" width="60%" height="10%" />
                </ContentLoader>
            </div>
        )
    }
    return (
        <div style={loaderStyles}>
            {loaderElements}
        </div>
    );
}