import { Color } from "../enums/Color";

export const whatsAppPage = {    
    mobile: {
        searchWrapper: {
            width: "-webkit-fill-available",
            position: "fixed" as "fixed",
            top: "50px",
            padding: "10px 5%",
            background: Color.White
        },
        searchInput: {
            height: "38px",
            width: "-webkit-fill-available",
            backgroundColor: Color.White,
            border: "2px solid " + Color.Green,
            borderRadius: "20px",
            padding: "0 15px"
        },
        form: {
            wrapper: {
                display: "flex",
                flexDirection: "column",
                position: "fixed" as "fixed",
                width: "100%",
                bottom: 0,
                padding: "10px 0",            
                backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/chat_background.jpg)",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover" as "cover",
                backgroundPosition: "center bottom",
                zIndex: 1
            },
            templateButton: {
                backgroundColor: Color.Green,
                promotion: {
                    backgroundColor: Color.ChathamsBlue
                },
                post: {
                    backgroundColor: Color.Tiber
                },
                facebook: {
                    backgroundColor: Color.BlueDianne
                },
                comment: {
                    backgroundColor: Color.Viridian
                },
                more: {
                    backgroundColor: Color.EveningSea
                },
                intro: {
                    backgroundColor: Color.Grape
                },
                location: {
                    backgroundColor: Color.OxfordBlue
                }
            },
            input: {
                width: "76%",
                height: "38px",
                backgroundColor: Color.White,
                border: "2px solid " + Color.Green,
                borderRadius: "20px",
                padding: "0 15px"
            },
            sendButton: {
                height: "42px",
                width: "42px",
                border: "none",
                borderRadius: "22px",                
                backgroundColor: Color.Green,
                marginLeft: "auto"                
            },
            fileButton: {
                height: "42px",
                width: "42px",
                border: "none",
                borderRadius: "22px",                
                backgroundColor: Color.Ash                
            },
            sendIcon: {
                width: "26px",
                height: "26px",                
                fill: Color.White                
            },
            fileIcon: {
                width: "26px",
                height: "26px",                
                fill: Color.SharpAsh                
            }
        }        
    },
    desktop: {        
        display: "flex",
        flexDirection: "row",
        contactListWrapper: {
            width: "30%"
        },
        searchWrapper: { 
            width: "30%",           
            top: 0,
            left: 0,
            padding: "unset",
            textAlign: "center",
            background: Color.LightAsh                      
        },
        searchInput: {            
            width: "86%",
            margin: "24px 0 12px 0"
        }, 
        contact: {
            container: {
                marginTop: "72px",
                height: "90vh",
                overflow: "auto" 
            },
            card: {
                display: "flex",
                flexDirection: "row",
                borderBottom: "2px solid " + Color.Green,
                margin: "0 5px",                
                padding: "5px 0",                
                alignItems: "center",
                cursor: "pointer",
                hovered: {
                    background: Color.LightAsh
                },
                selected: {
                    background: Color.Ash
                }
            },
            logo: {
                height: "50px",
                width: "50px",
                margin: "10px",
                objectFit: "contain",                
                borderRadius: "50%"
            },
            name: {},
            number: {},
            unreadCount: {
                height: "18px",
                lineHeight: "18px",
                width: "18px",
                borderRadius: "16%",
                background: Color.DeepBlack,
                color: Color.White,
                fontSize: "12px",
                margin: "0 24px 0 auto",
                textAlign: "center"    
            }
        },
        messageWrapper: {            
            width: "70%"
        },
        selectedContact: {
            position: "fixed" as "fixed",
            top: 0,
            right: 0,            
            height: "74px",
            width: "70%",
            display: "flex",
            flexDirection: "row",
            background: Color.LightAsh,
            alignItems: "center",            
            borderLeft: "1px solid " + Color.Ash    
        },
        messageContent: {                
            height: "76vh",            
            overflowY: "scrol",
            overflowX: "hidden",
            marginTop: "72px",
            backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/chat_background.jpg)",
            backgroundSize: "100%"
        },
        messageLoading: {
            width: "fit-content" as "fit-content",
            display: "flex",
            padding: "8px",
            margin: "20px 48px 0 auto",
            border: "none",
            borderRadius: "10px 0 10px 10px",
            backgroundColor: Color.Gossip,
            boxShadow: "0 0 2px rgb(0 0 0 / 0.2)"
        },
        messageList: {
            padding: "0 48px"            
        },
        message: {
            width: "fit-content" as "fit-content",            
            display: "flex",            
            padding: "8px",
            border: "none",            
            boxShadow: "0 0 2px rgb(0 0 0 / 0.2)",
            wordBreak: "break-word",
            sent: {                
                maxWidth: "80%",                
                margin: "20px 0 0 auto",                
                borderRadius: "10px 0 10px 10px",
                backgroundColor: Color.Gossip                
            },
            receive: {                
                maxWidth: "90%",                
                margin: "10px auto 0 0",                
                borderRadius: "0 10px 10px 10px",
                backgroundColor: Color.White                
            },
            fileWrapper: {
                display: "flex",
                flexDirection: "column",
                marginTop: "20px"
            },
            file: {
                width: "460px",
                height: "460px",
                borderRadius: "18px 18px 0 0",
                marginLeft: "auto"                
            },
            fileUploadButton: {
                display: "flex",
                width: "460px",
                height: "40px",
                borderRadius: "0 0 18px 18px",
                backgroundColor: Color.Green,
                color: Color.White,
                fontSize: "16px",
                fontWeight: "bold",
                marginLeft: "auto",
                justifyContent: "center",
                alignItems: "center"
            },
            uploadIcon: {
                fill: Color.White,
                marginRight: "12px"
            },
            closeIcon: {
                margin: "12px 12px -37px auto",
                cursor: "pointer",
                zIndex: "1"
            },
            image: {
                width: "360px",
                height: "360px",
                borderRadius: "10px",
                marginLeft: "auto"
            },
            template: {
                display: "flex",
                flexDirection: "column"
            }
        },        
        form: {  
            wrapper: {          
                width: "70%",
                backgroundImage: "none",
                background: Color.White,
                padding: "12px",
                borderLeft: "1px solid " + Color.Ash
            },
            templateButton: {
                height: "2.6vw",
                lineHeight: "2.6vw",                
                borderRadius: "20px",
                color: Color.White,
                padding: "0 15px",
                fontSize: "16px",
                fontWeight: "bold",
                marginRight: "16px"
            },
            form: {                
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "8px"
            },
            input: {
                width: "-webkit-fill-available",
                height: "2.3vw",
                borderRadius: "20px"                
            },
            sendButton: {
                height: "2.6vw",
                width: "2.8vw",            
                borderRadius: "50%",            
                margin: "0 32px 0 16px"            
            },
            fileButton: {
                height: "2.6vw",
                width: "2.8vw",            
                borderRadius: "50%",            
                margin: "0 16px 0 0"            
            },
            sendIcon: {
                width: "20px",
                height: "20px"
            },
            fileIcon: {
                width: "18px",
                height: "18px"
            }
        },
        disabled: {
            border: "1px solid " + Color.Red
        }       
    }
}