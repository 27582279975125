import React, { useContext, useEffect, useState } from 'react';
import { merge } from 'react-merge';
import { useMediaQuery } from 'react-responsive';
import { StyleContext } from '../../../providers/StyleContextProvider';
import { InputValidation } from '../../../models/InputValidation';
import { ReactComponent as EyeOpen } from '../../../assets/svgs/eye-open.svg';
import { ReactComponent as EyeClose } from '../../../assets/svgs/eye-close.svg';

interface Props { 
    styles?: any;       
    type: string;     
    name: string;
    value: string | number;  
    label: string;    
    onChange(event: React.ChangeEvent<HTMLInputElement>): void;
    handleBlur?(event?: React.FocusEvent<HTMLInputElement>): void;
    disabled?: boolean;
    validation?: InputValidation;
};

export const TextInput: React.FC<Props> = (props) => {    
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("input"));
    const [focused, setFocused] = useState<boolean>(false);  
    const [showPassword, setShowPassword] = useState<boolean>(false);  
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const validation = props.validation;    

    useEffect(() => {          
        setStyles(styleContext.getComponentStyle("input"));  
        let extendedStyles = merge(styles, props.styles);
        setStyles(extendedStyles);                                                                 
    }, [isMobile]);

    function handleOnBlur(event?: React.FocusEvent<HTMLInputElement>){
        setFocused(false);
        props.handleBlur && props.handleBlur(event);
    }

    return (
        <>
            <div style={styles.wrapper} onClick={() => setFocused(true)}>
                <input style={{...styles.input, ...(validation?.invalid && styles.input.invalid), ...(validation?.valid && styles.input.valid)}}
                    type={showPassword ? "text" : props.type}
                    name={props.name} 
                    value={props.value} 
                    onChange={props.onChange}
                    onBlur={handleOnBlur}
                />
                <label style={{...styles.label, ...((props.value || focused) && styles.label.focus), ...(validation?.invalid && styles.label.invalid)}}>{props.label}</label>
                {props.type === "password" && <span onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <EyeClose style={styles.eyeIcon} /> : <EyeOpen style={styles.eyeIcon} />}
                </span>
            }
            </div>
            {validation?.invalidMessage && <div style={styles.invalidMessage}>{validation.invalidMessage}</div>}   
        </>
    );
}