import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { StyleContext } from '../../providers/StyleContextProvider';

interface Props {    
    styles?: any;
    message?: string;    
};

export const NoData : React.FC<Props> = (props) => {
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("noData"));
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
        
    useEffect(() => {    
        setStyles(styleContext.getComponentStyle("noData"));        
    }, [isMobile]);

    return (
        <div style={styles}>{props.message ? props.message : "No data available for this search"}</div>
    );
}