import { dialog } from "./Dialog";
import { footer } from "./Footer";
import { homePage } from "./HomePage";
import { input } from "./Input";
import { loginHeader } from "./LoginHeader";
import { loginPage } from "./LoginPage";
import { newAds } from "./NewAds";
import { noData } from "./NoData";
import { pagination } from "./Pagination";
import { select } from "./Select";
import { teachersPage } from "./TeachersPage";
import { whatsAppPage } from "./WhatsAppPage";

export const styleDictionary = new Map<string, any>();

styleDictionary.set("input", input);
styleDictionary.set("dialog", dialog);
styleDictionary.set("loginHeader", loginHeader);
styleDictionary.set("footer", footer);
styleDictionary.set("noData", noData);
styleDictionary.set("select", select);
styleDictionary.set("pagination", pagination);
styleDictionary.set("homePage", homePage);
styleDictionary.set("loginPage", loginPage);
styleDictionary.set("whatsAppPage", whatsAppPage);
styleDictionary.set("teachersPage", teachersPage);
styleDictionary.set("newAds", newAds);