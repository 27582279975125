import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { FooterTexts as Texts } from '../../helpers/LayoutTexts';
import { StyleContext } from '../../providers/StyleContextProvider';
import { ReactComponent as Location } from '../../assets/svgs/location.svg';
import { ReactComponent as Phone } from '../../assets/svgs/phone.svg';
import { ReactComponent as Mail } from '../../assets/svgs/mail.svg';
import { ReactComponent as LinkedIn } from '../../assets/svgs/linkedin.svg';
import { ReactComponent as Whatsapp } from '../../assets/svgs/whatsapp.svg';
import { ReactComponent as Facebook } from '../../assets/svgs/facebook.svg';
import { ReactComponent as Instagram } from '../../assets/svgs/instagram.svg';
import { ReactComponent as Twitter } from '../../assets/svgs/twitter.svg';

export const Footer: React.FC = () => {   
    const styleContext = useContext(StyleContext);
    const[styles, setStyles] = useState<any>(styleContext.getComponentStyle("footer"));                
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    
    useEffect(() => { 
        setStyles(styleContext.getComponentStyle("footer"));
    }, [isMobile]);

    return (
        <div style={styles}>
        <div style={styles.title}>{"TuteClass"}</div> 
        <div style={styles.details}>
            <div style={styles.wrapper}>
            <Location style={styles.smallIcon}/>
            <div>{Texts.address}</div>
            </div>
            <div style={styles.wrapper}>
            <Mail style={styles.smallIcon}/>
            <div>{Texts.email}</div>
            </div>
            <div style={styles.wrapper}>
            <Phone style={styles.smallIcon}/>
            <div>{Texts.telephone}</div>
            </div>
        </div>         
        <div style={styles.copyright}>
            <div style={styles.socialIconWrapper}>
            <LinkedIn style={styles.largeIcon}/>
            <a href="https://wa.me/94772043480" target="_blank">
                <Whatsapp style={styles.largeIcon}/>
            </a>  
            <Facebook style={styles.largeIcon}/>
            <Instagram style={styles.largeIcon}/>
            <Twitter style={styles.largeIcon}/>
            </div>
            {Texts.copyright}
        </div>
        </div>
    );
}