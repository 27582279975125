import { Color } from "../enums/Color";

export const newAds = {
    mobile: {
        imageWarning: {
            margin: "30px 0 5px 0",
            fontSize: "12px"
        },
        imageInput: {
            height: "198px",  
            width: "-webkit-fill-available",            
            border: "none",
            borderRadius: "5px",            
            objectFit:"cover",
            cursor: "pointer"
        },
        imageInvalid: {
            border: "1px solid " + Color.Red,            
            WebkitFilter: "grayscale(1)", 
            filter: "grayscale(1)"
        },
        invalidMessage: {
            color: Color.Red,
            fontSize: "14px",
            fontWeight: "700",
            paddingLeft: "10px"
        },
        submitButton: {
            height: "50px",
            width: "-webkit-fill-available",
            background: Color.Green,
            color: Color.White,
            border: "none",
            borderRadius: "5px",
            fontWeight: "bold",
            margin: "30px 0"
        },
        responseDialog: {            
            backdrop: {
                backgroundColor: Color.Black,
                opacity: 0.15,
                position: "fixed" as "fixed",
                left: "0",
                right: "0",
                bottom: "0",
                top: "0"
            },
            content: {
                background: Color.White,
                position: "fixed",
                top: "35%",
                right: "5%",
                width: "80%",
                padding: "10px 5%",                        
                borderRadius: "10px",
                zIndex: "2",
                boxShadow: "1px 0px 7px rgba(0,0,0,0.5)",  
                transform: "translateX(0)"
            }
        },
        switch: {
            wrapper: {
                display: "flex",
                marginTop: "18px"
            },
            label: {
                fontSize: "18px",
                fontWeight: "bold",
                color: Color.Green
            }
        }
    },
    desktop: {
        width: "40%",
        margin: "32px auto 0",
        title: {
            fontSize: "32px",
            color: Color.Green,
            marginBottom: "32px",
            textAlign: "center"
        },
        label: {
            fontWeight: "bold",
            marginLeft: "10px"
        },
        imageInput: {
            height: "auto",
            borderRadius: "10px"            
        },
        submitButton: {
            height: "60px",
            width: "-webkit-fill-available",            
            borderRadius: "10px",            
            fontSize: "20px",
            marginTop: "40px"
        },
        responseDialog: {
            content:{
                top: "28%",
                right: "unset",
                width: "36%",
                padding: "2%",
                borderRadius: "10px",
                boxShadow: "1px 0px 7px rgba(0,0,0,0.5)"
            }
        },
        responseTitle: {
            fontSize: "24px",
            color: Color.Green,
            margin: "0 auto 24px auto",
            textAlign: "center"
        },
        responseMessage: {
            fontSize: "18px"
        },
        responseButton: {
            backgroundColor: Color.Green,
            color: Color.White,
            borderRadius: "10px",
            padding: "12px 24px",
            float: "right"
        }
    }
}