import React from 'react';
import { Admin } from '../models/Admin';

interface Props {
    children: React.ReactNode;
}

export interface AuthContextType {        
    getLoggedAdmin(): any;   
}

export const AuthContext = React.createContext<AuthContextType>({            
    getLoggedAdmin: () => {}    
});

export const AuthContextProvider: React.FC<Props> = (props) => {             

    const getAdmin = (): Admin | null => {           
        const adminString = localStorage.getItem("admin");          
        if(adminString !== null) {            
            const admin: Admin = JSON.parse(adminString);
            return admin;
        } else {
            return null;
        }       
    }

    return (
        <AuthContext.Provider value={{                        
            getLoggedAdmin: getAdmin          
        }}>           
            {props.children}
        </AuthContext.Provider>
    );
}